<template>
  <div class="projekt4 has-text-centered">
    <ImageGallery class=".gallery-one desktop-only" :images="galleryImages" :columns="2" :width="'calc(90vw - 100px)'"
      :mirror-horizontal="true" />
    <ImageGallery class=".gallery-one touch-only" :images="galleryImages" :columns="1" :width="'calc(85vw)'"
      :mirror-horizontal="false" />
  </div>
</template>

<script>
import ImageGallery from '@/components/ImageGalleries/ImageGallery.vue';

export default {
  name: 'Projekt4',
  components: {
    ImageGallery
  },
  data() {
    return {
      galleryImages: [
        {
          text: [
            { text: "Jump'n Run", classes: ['title'] },
            { text: "Schulprojekt mit Janik Meier, Kim Roos und Anish Muthukumaran, Oktober 2020, 6 Wochen", classes: ['subtitle'] },
          ]
        },
        { url: require('@/assets/thumbnails/img/jumpnrun_img2 (Large).png') },
        {
          text: [
            { text: 'Idee', classes: ['title'] },
            { text: 'Wir haben uns als Gruppe am Projekttag der Informatikschule gefunden, mit dem gemeinsamen Ziel, ein Videospiel zu entwickeln.', classes: [] },
          ]
        },
        {
          text: [{ text: 'Wir haben uns dazu entschieden, einen 2D Platformer mit Unity zu programmieren, da wir dazu gute Lernresourcen im Internet gefunden haben.', classes: [] }]
        },
        {
          text: [
            { text: 'Konzept', classes: ['title'] },
            { text: 'Das Spiel ist ein 2D Platformer, in welchem die Spielenden durch verschiedene Welten hüpfen.', classes: [] },
          ]
        },
        {
          text: [{ text: 'Die verschiedenen Levels werden von Hand gebaut, wobei wir verschiedene Landschaften zeigen, und verschiedene Spielmechaniken implementieren wollten. Die Levels und Spielmechaniken sind folgende: ', classes: [] }]
        },
        {
          text: [{ text: 'Ein Tutorial Level, in welchem die Spielenden lernen, sich zu bewegen. Es besteht aus fliegenden Grasplatformen und einem Backsteintunnel.', classes: [] }]
        },
        {
          text: [{ text: 'Die Wüste, in welcher Treibsand und Kakteen ausgewichen werden muss und eine Pyramide mit Geheimgang.', classes: [] }]
        },
        {
          text: [{ text: 'Eine Eislandschaft, wo die Spielenden vertikale Eiswände erklimmen, und mit Trampolinen Schluchten überqueren.', classes: [] }]
        },
        {
          text: [{ text: 'Der Vulkanausbruch, und eine lavaüberzogene Graslandschaft mit vielen herumlaufenden Gegnern.', classes: [] }]
        },
        {
          text: [
            { text: 'Meine Rolle', classes: ['title'] },
            { text: 'Die verschiedenen Aufgaben im Projekt haben wir unter uns aufgeteilt.', classes: [] },
          ]
        },
        {
          text: [{ text: 'Ich habe die Tilesets der Levels entwickelt, das heisst, ich habe den Sandstein, die Kakteen, die Trampoline, etc. gezeichnet. Diese, und alle anderen Grafiken, haben wir mit MS-Paint erstellt, in einem pixelisierten Stil. ', classes: [] }]
        },
        {
          text: [{ text: 'Während meine Mitschüler die Levels und deren Hintergründe designed haben, programmierte ich den Spieler-Kontroller, also das Script, mit welchem der Spielcharakter bewegt wird.', classes: [] }]
        },
        { url: require('@/assets/thumbnails/img/jumpnrun_desert (Large).png') },
        {
          text: [
            { text: 'Endprodukt', classes: ['title'] },
            { text: 'Unser Endprodukt ist das fertiges Spiel, mit den oben beschriebenen Levels und Mechaniken.', classes: [] },
          ]
        },
        {
          text: [{ text: 'Wir haben das Projekt auf der Schulwebsite veröffentlicht und gegen andere Schüler gespielt, indem wir die Zeit stoppten, wie schnell das ganze Spiel durchspielen kann. Wir waren sehr stolz auf das Spiel.', classes: [] }]
        },
        {
          text: [{ text: 'Durch das verschiedene Aussehen der Levels und deren einzigartigen Spielmechaniken macht es Spass und bleibt gut in Erinnerung.', classes: [] }]
        },
        { url: require('@/assets/thumbnails/img/jumpnrun_ice (Large).png') },
        { url: require('@/assets/thumbnails/img/jumpnrun_lava (Large).png') },
        // {
        //   text: [{ text: '', classes: [] }]
        // },
        // { url: require('@/assets/thumbnails/thumbnail dodge-game (Large).png'), alt: 'Custom Image 1' },
        // { url: require('@/assets/thumbnails/thumbnail dodge-game (Large).png'), alt: 'Custom Image 1' },
      ],
    };
  }
}
</script>
