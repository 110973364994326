<template>
  <div class="projekt1 container has-text-centered">
    <ImageGallery class=".gallery-one desktop-only" :images="galleryImages" backgroundcolor="var(--cblue)" :columns="2" :width="'calc(90vw - 100px)'"
      :mirror-horizontal="true" />
    <ImageGallery class=".gallery-one touch-only" :images="galleryImages" :columns="1" :width="'calc(85vw)'"
      :mirror-horizontal="false" />
  </div>
</template>

<script> 
import ImageGallery from '@/components/ImageGalleries/ImageGallery.vue';

export default {
  name: 'Projekt1',
  components: {
    ImageGallery
  },
  data() {
    return {
      galleryImages: [
        {
          text: [
            { text: "JetFighters", classes: ['title'] },
            { text: "Schulprojekt mit Janik Meier und Joël Herren, Dezember 2023, 18 Wochen", classes: ['subtitle'] },
          ]
        },
        { url: require('@/assets/thumbnails/thumbnail jetfighters-makeover (Large).png') },
        // { url: require('@/assets/thumbnails/thumbnail dodge-game (Large).png'), alt: 'Custom Image 1' },
        {
          text: [
            { text: 'Konzept', classes: ['title'] },
            { text: 'JetFighters ist ein Online Multiplayer Spiel. Es fällt in die .IO-Games Spalte: in diesem Gelegenheitsspiel kämpfen die Spielenden in Echtzeit mit Kampfjets gegeneinander.', classes: [] },
          ]
        },
        {
          text: [{ text: 'Man kann gegen Freunde, oder gegen zufällige Gegner im Internet spielen. Dabei muss man geschickt manövrieren, um den Gegner in die Schussbahn zu kriegen.' }]
        },
        {
          text: [{ text: 'Das Spielfeld ist ausserdem mit herumziehender Sturmwolken bedeckt, welchen die Spielenden ausweichen müssen. Damit ändert sich der Flugraum dynamisch.' }]
        },
        {
          text: [{ text: 'Prozess', classes: ['title'] },
          { text: 'Der Prototyp entstand in einer schulischen Projektarbeit mit zwei meiner Mitschüler, Janik Meier und Joël Herren im Jahr 2023. ' }]
        },
        {
          text: [{ text: 'Das Spielkonzept besteht bereits seit einem anderen Schulprojekt im 2021. Unser Ziel war es, einen Prototypen mit Multiplayer-Funktionalität zu erstellen, und diesen im Internet bereitzustellen.' }]
        },
        {
          text: [{ text: 'Um den Prototypen und die Dokumentation rechtzeitig fertigzustellen, arbeiteten wir nach einem Zeitplan. Da wir in diesem Projekt vor allem programmieren wollten, erstellten wir keine der Grafiken im Spiel selber. Wir nutzen stattdessen CC0-lizenzierte Grafiken, welche wir z.B. auf opengameart.org gefunden haben.' }]
        },
        {
          text: [
            { text: 'Erste Prototypen', classes: ['title'] },
            { text: 'In diesem Projekt war ich unter anderem für das Multiplayer Networking verantwortlich.', classes: [] },
          ]
        },
        {
          text: [{ text: 'In ersten Prototypen (HTML, JavaScript, Kommunikation mit WebSockets) machte ich mich mit den Funktionsweisen von Game-Servern, Server-Client-Synchronisation und Netzwerkverzögerungs-Kompensation bekannt.' }]
        },
        {
          text: [{ text: ' Danach entschieden wir uns dazu, die Server-Client-Synchronisation, sowie die Kommunikation zwischen Server und Client einem Framework zu überlassen. Wir wählten dazu das Colyseus Framework, da es eine sehr detaillierte Dokumentation besitzt.' }]
        },
        { url: require('@/assets/thumbnails/thumbnail jetfighters (Large).png'), alt: 'Custom Image 1' },
        { url: require('@/assets/thumbnails/thumbnail jetfighters-1.1 (Large).png'), alt: 'Custom Image 1' },
        // { url: require('@/assets/thumbnails/thumbnail dodge-game (Large).png'), alt: 'Custom Image 1' },
        {
          text: [
            { text: 'Implementation des Spieles', classes: ['title'] },
            { text: '', classes: [] },
          ]
        },
        {
          text: [{ text: 'Zusammen mit meinen Kollegen programmierte ich die Spiellogik. Die Spielerinputs werden an den Game Server geschickt, welcher diese verarbeitet.' }]
        },
        {
          text: [{ text: 'Dann bewegt der Server die Spieler und führt Aktionen aus wie das Abfeuern der Waffen, das Töten der Spieler, welche getroffen wurden, oder das Wiederbeleben der Spieler, welche nochmals spielen wollen. Das Colyseus Framework übernimmt dann die Synchronisation des Spielstandes an die verschiedenen Spieler.' }]
        },
        {
          text: [{ text: 'Die Clients stellen das Spielgeschehen im Webbrowser dar. Mit der Phaserio Game Engine können ganz einfach Grafiken wie Jets oder Schüsse auf den Bildschirm hinzugefügt werden. Danach folgte die Implementation der Sturmwolken. ' }]
        },
        {
          text: [{ text: 'Diese werden auf dem Server erstellt und bewegen sich langsam über das Spielfeld. Wenn ein Jet in eine Wolke hineinfliegt, wird er beschädigt, weswegen die Spieler diese meiden sollten. Für die Darstellung der Wolken auf dem Client wollten wir einen Shader verwenden.' }]
        },
        {
          text: [{ text: 'Ich habe deswegen in diesem Projekt erlernt, wie man einen einfachen Shader programmiert und in Phaserio einfügt. Der Shader generiert eine dynamische graue Wolkenmasse, welche sich ähnlich wie eine Wolke bewegt. Als letztes fügten wir die Highscores dazu: zu jedem Spieler wird angezeigt, wieviele Abschüsse er erzielt hat.' }]
        },
        { url: require('@/assets/thumbnails/img/jetfighters_1 (Large).png'), alt: 'Custom Image 1' },
        {
          text: [
            { text: 'Webhosting', classes: ['title'] },
            { text: 'Die Spielenden erreichen das Spiel über die Website www.jetfighters-angular-server.onrender.com. Momentan läuft darauf unser Prototyp.', classes: [] },
          ]
        },
        {
          text: [{ text: 'Wir nutzen einen kostenlosen Webhosting Service. Dabei haben wir noch ein ungelöstes Problem: manchmal, wenn man die Website besucht, dauert es ein paar Minuten, bis sie lädt. Danach aber läuft das Spiel.' }]
        },
        // { url: require('@/assets/thumbnails/thumbnail dodge-game (Large).png'), alt: 'Custom Image 1' },
        // Add more images as needed
      ],
    };
  }
}
</script>

<style scoped lang="scss">
@import "/node_modules/bulma/bulma";

.projekt1>* {
  margin: auto;
}
</style>