import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Projekt1 from '../views/Projekt1.vue'
import Projekt2 from '../views/Projekt2.vue'
import Projekt3 from '../views/Projekt3.vue'
import Projekt4 from '../views/Projekt4.vue'
import Projekt5 from '../views/Projekt5.vue'
import Hausaufgabe from '../views/Hausaufgabe.vue'
import Diverses from '../views/Diverses.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/projekt1',
    name: 'Projekt1',
    component: Projekt1
  },
  {
    path: '/projekt2',
    name: 'Projekt2',
    component: Projekt2
  },
  {
    path: '/projekt3',
    name: 'Projekt3',
    component: Projekt3
  },
  {
    path: '/projekt4',
    name: 'Projekt4',
    component: Projekt4
  },
  {
    path: '/projekt5',
    name: 'Projekt5',
    component: Projekt5
  },
  {
    path: '/hausaufgabe',
    name: 'Hausaufgabe',
    component: Hausaufgabe
  },
  {
    path: '/diverses',
    name: 'Diverses',
    component: Diverses
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
