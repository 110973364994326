<template>
  <div class="home">
    <FreeformGallery/>
  </div>
</template>

<script>
import FreeformGallery from '@/components/FreeformGallery.vue';

export default {
  name: 'Home',
  components: {
    FreeformGallery
}
}
</script>

<style lang="scss">

</style>