<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';

const touchNavIsOpen = ref(false);

const toggleTouchNav = () => {
  if (touchNavIsOpen.value) closeTouchNav();
  else openTouchNav();
};

const handleResize = () => {
  closeTouchNav();
};

const openTouchNav = () => {
  document.getElementById("menubars").classList.add("change");
  document.getElementById("menuhex").classList.add("hidden");
  document.getElementById("myThing").classList.add("hidden");
  document.getElementById("touchmenuitems").classList.remove("hidden-right");
  document.getElementById("touchmenuul").classList.remove("uninteractable");
  touchNavIsOpen.value = true;
};

const closeTouchNav = () => {
  document.getElementById("menubars").classList.remove("change");
  document.getElementById("menuhex").classList.remove("hidden");
  document.getElementById("myThing").classList.remove("hidden");
  document.getElementById("touchmenuitems").classList.add("hidden-right");
  document.getElementById("touchmenuul").classList.add("uninteractable");
  touchNavIsOpen.value = false;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
});
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});

</script>

<template>
  <nav>
    <div id="menuhex" class="hexagon"></div>

    <div id="menubars" @click="toggleTouchNav()">
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
    </div>
    <ul>


      <div id="desktopmenuitems">
        <li><router-link class="is-size-3" to="/" :class="{ active: $route.path === '/' }">Home</router-link></li>
        <li><router-link class="is-size-3" to="/hausaufgabe" :class="{ active: $route.path === '/hausaufgabe' }">Hausaufgabe</router-link></li>
        <li><router-link class="is-size-3" to="/projekt1" :class="{ active: $route.path === '/projekt1' }">JetFighters</router-link></li>
        <li><router-link class="is-size-3" to="/projekt2" :class="{ active: $route.path === '/projekt2' }">Scarlet Flight</router-link></li>
        <li><router-link class="is-size-3" to="/projekt3" :class="{ active: $route.path === '/projekt3' }">FPS Runner</router-link></li>
        <li><router-link class="is-size-3" to="/projekt4" :class="{ active: $route.path === '/projekt4' }">Jump'n Run</router-link></li>
        <li><router-link class="is-size-3" to="/diverses"
            :class="{ active: $route.path === '/diverses' }">Diverses</router-link></li>

        <div class="arrow"></div>
      </div>
    </ul>
    <ul id="touchmenuul" class="uninteractable">
      <div id="touchmenuitems" class="hidden-right">
        <li><router-link class="is-size-3 text-wrap" @click="toggleTouchNav()" to="/"
            :class="{ active: $route.path === '/' }">Home</router-link></li>
        <li><router-link class="is-size-3 text-wrap" @click="toggleTouchNav()" to="/hausaufgabe"
            :class="{ active: $route.path === '/hausaufgabe' }">Hausaufgabe</router-link></li>
        <li><router-link class="is-size-3 text-wrap" @click="toggleTouchNav()" to="/projekt1"
            :class="{ active: $route.path === '/projekt1' }">JetFighters</router-link></li>
        <li><router-link class="is-size-3 text-wrap" @click="toggleTouchNav()" to="/projekt2"
            :class="{ active: $route.path === '/projekt2' }">Scarlet Flight</router-link></li>
        <li><router-link class="is-size-3 text-wrap" @click="toggleTouchNav()" to="/projekt3"
            :class="{ active: $route.path === '/projekt3' }">FPS Runner</router-link></li>
        <li><router-link class="is-size-3 text-wrap" @click="toggleTouchNav()" to="/projekt4"
            :class="{ active: $route.path === '/projekt4' }">Jump'n Run</router-link></li>
        <li><router-link class="is-size-3 text-wrap" @click="toggleTouchNav()" to="/diverses"
            :class="{ active: $route.path === '/diverses' }">Diverses</router-link></li>
      </div>
    </ul>
  </nav>
  <router-view id="myThing" />
</template>



<style lang="scss">
@import "/node_modules/bulma/bulma";


:root {
  // #region color palettes
  // // secondary pink-blue
  // --caccent: #ff82a9;
  // --clight: #ffebe7;
  // --cblue: #7f95d1;
  // --cgray: #ffc0be;
  // --cdark: #000000;

  // // original blue
  // --caccent: #984447;
  // --clight: #add9f4;
  // --cblue: #476c9b;
  // --cgray: #468c98;
  // --cdark: #101419;

  // // tertiary brown
  // --caccent: #a71d31;
  // --clight: #f1f0cc;
  // --cblue: #8d775f;
  // --cgray: #d5bf86;
  // --cdark: #3f0d12;

  // // fourth orange-cyan
  // --caccent: #FF715B;
  // --clight: #FFFFFF;
  // --cblue: #523F38;
  // --cgray: #1EA896;
  // --cdark: #4C5454;

  // // fifth alien
  // --caccent: #F75C03;
  // --clight: #04A777;
  // --cblue: #820263;
  // --cgray: #D90368;
  // --cdark: #291720;

  // // sixth dva
  // --caccent: #F46197;
  // --clight: #FFFDF7;
  // --cblue: #2CA58D;
  // --cgray: #84BC9C;
  // --cdark: #0A2342;

  // // seventh swamp
  // --caccent: #94A187;
  // --clight: #E9BCB7;
  // --cblue: #29524A;
  // --cgray: #C5AFA0;
  // --cdark: #06070E;

  // // eighth damp rainbow
  // --caccent: #721817;
  // --clight: #E0E0E2;
  // --cblue: #0B6E4F;
  // --cgray: #FA9F42;
  // --cdark: #2B4162;

  // // ninth ice cream
  // --caccent: #FF5154;
  // --clight: #FFFFFF;
  // --cblue: #FAFF7F;
  // --cgray: #FF88DC;
  // --cdark: #91A6FF;

  // // tenth bee
  // --caccent: #A61C3C;
  // --clight: #DAD6D6;
  // --cblue: #F4AC45;
  // --cgray: #92BFB1;
  // --cdark: #694A38;

  // eleventh the thing?
  --caccent: #82204A;
  --clight: #EFF7FF;
  --cblue: #E8DB7D;
  --cgray: #558C8C;
  --cdark: #231123;

  // #endregion

  --cbackground: var(--clight);

  --cnavbackground: var(--cgray);
  --cnavhoverbackground: var(--cgray);
  --cnavtext: var(--cdark);
}

@include desktop {
  #myThing {
    margin-left: 100px;
    padding: 40px 80px 80px 80px;
  }
}


.gallery-image {
  background-color: var(--cblue);
}

body {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */

  background: var(--cbackground);
  // background: radial-gradient(circle, var(--cdark) 100%, var(--cblue) 100%);

  overflow-x: hidden;

  min-height: 100vh;
}

body::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.hexagon {
  -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.text-wrap {
  word-break: break-all;
}

@include touch {
  #myThing {
    padding: 40px 40px 120px 40px;
  }

  #desktopmenuitems {
    display: none;
  }

  #touchmenuitems {
    top: 0px;
    left: 0px;

    width: 100%;
    width: calc(100vw);
    height: 100vh;

    padding-left: 100px;
    background-color: var(--cnavbackground);

    transition: transform 0.3s;
  }

  #menuhex {
    position: fixed;
    backface-visibility: hidden;
    top: -45px;
    left: -45px;

    width: 150px;
    height: calc(150px *2 /sqrt(3));

    background-color: var(--cnavbackground);
  }

  #menubars {
    cursor: pointer;
    margin-top: 30px;
    margin-left: 15px;

    position: fixed;
    backface-visibility: hidden;
    z-index: 1;
  }
}

@include desktop {

  #touchmenuitems {
    display: none;
    width: 70vw;
  }

  #menubars {
    display: none;
  }

  #menuhex {
    display: none;
  }

  #desktopmenuitems {
    height: 100vh;
    background-color: var(--cnavbackground);

    transform: translate(-250px);
    transition: transform 0.3s;
  }

  #desktopmenuitems:hover {
    transform: translate(0);
  }

  #desktopmenuitems {
    width: 350px;
    position: fixed;
  }

  #desktopmenuitems>.arrow {
    position: fixed;
    top: 50%;
    right: 40px;

    width: 60px;
    height: 60px;
    border-right: var(--cnavtext) solid 3px;
    border-top: var(--cnavtext) solid 3px;
    transform: rotate(45deg);

    opacity: 1.0;
    transition: opacity 0.3s;
  }

  #desktopmenuitems:hover>.arrow {
    opacity: 0.0;
  }
}

.title {
  height: 38px;
}

.tiletext {
  margin: auto;
  overflow: hidden;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

@include touch {
  .tiletext {
    font-size: small;
    width: 90%;
  }

  .desktop-only {
    display: none;
  }
}

@include desktop {
  .tiletext {
    font-size: medium;
    font-weight: normal;
    width: 70%;
  }

  .touch-only {
    display: none;
  }
}

.hidden {
  display: none;
}

.hidden-right {
  transform: translate(100vw);
}

.uninteractable {
  pointer-events: none;
}


// #region menubars

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 4px;
  background-color: var(--cnavtext);
  margin: 8px 15px;
  transition: 0.4s;
}

.change .bar1 {
  transform: translate(0, 12px) rotate(-45deg);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: translate(0, -12px) rotate(45deg);
}

// #endregion



.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

nav {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
  z-index: 5;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 200px;
  position: absolute;
}

nav li a {
  display: block;
  color: var(--cnavtext);
  padding: 8px 16px;
  text-decoration: none;
}

nav li {
  background-color: var(--cnavbackground);
}

nav li a.active {
  background-color: var(--cnavtext);
  color: var(--cnavbackground);
  font-weight: bold;

  transition: background-color 0.3s;
}

#desktopmenuitems:not(:hover) li a.active {
  background-color: var(--cnavbackground);
  color: var(--cnavtext);
  font-weight: normal;
}

nav li a:hover:not(.active) {
  background-color: var(--cnavhoverbackground);
  color: var(--cnavtext);
}</style>
