<template>
    <router-link :to="link" class="hexagon hex">
        <img :src="require(`@/assets/thumbnails/${thumbnail}`)">
        <div class="hexoverlay"></div>
        <div class="edgeshadows"></div>
        <div class="hex-box has-text-centered">
            <div class="hex-content">
                <p class="is-size-2">{{title}}</p>
                <p class="subtext is-size-5">{{subtext}}</p>
            </div>
        </div>
    </router-link>
</template>
  
<script>

export default {
    name: 'HexThumbnail',
    props: ['title', 'subtext', 'thumbnail', 'link']
}

</script>
  
<style lang="scss">

@import "/node_modules/bulma/bulma";

.hex {
    display: flex;
    position: absolute;
    background-color: var(--cgray);

    align-items: center;

    transform: scale(1);
    transition: transform 0.15s;
}
.hex:hover {
    transform: scale(1.06);
}

.hex > .hexoverlay {
    position: absolute;
    background-color: var(--cgray);
    width: 100%;
    height: 100%;
    z-index: 1;

    opacity: 0.0;
    transition: opacity 0.3s;
}
.hex:hover > .hexoverlay {
    opacity: 0.85;
}

.hex > .edgeshadows {
    position: absolute;
    width: 100%;
    height: 100%;

    background: rgb(0,212,255);
    background: radial-gradient(circle, rgba(0,212,255,0) 0%, rgba(0,0,0,0.10) 50%, rgba(0,0,0,0.5) 100%);
    opacity: 0.5;
    z-index: 1;

    transition: opacity 0.3s;
}

.hex:hover > .edgeshadows {
    opacity: 0.0;
}

.hex img {
  position: absolute;
  z-index: 1;
  // height: 100%;
  width: 100%;
  
  filter: grayscale(0.0);
  transition: filter 0.3s;
}
.hex:hover img {
  filter: grayscale(1.0);
}

.subtext {
    padding: 0 10% 0 10%;
}

</style>