<template>
  <div class="hex-gallery">
    <HexThumbnail 
      title="Im Moment" 
      thumbnail="homework_quests (Large).jpg" 
      link="/hausaufgabe"
      subtext="Meine Hausaufgabe."></HexThumbnail>

    <HexThumbnail 
      title="JetFighters" 
      thumbnail="img/jetfighters_img1 (Large).png" 
      link="/projekt1"
      subtext="Ein Online-Mehrspielerspiel"></HexThumbnail>

    <HexThumbnail 
      title="Scarlet Flight" 
      thumbnail="img/scarletflight_img1 (Large).png" 
      link="/projekt2"
      subtext="Enstanden in einem Game Jam"></HexThumbnail>

    <HexThumbnail 
      title="FPS Runner" 
      thumbnail="img/fpsrunner_img1 (Large).png" 
      link="/projekt3"
      subtext="Komplexe Systeme in Unity"></HexThumbnail>

    <HexThumbnail 
      title="Jump'n Run" 
      thumbnail="img/jumpnrun_img1 (Large).png" 
      link="/projekt4"
      subtext="Mein erstes Gameprojekt in einem Team"></HexThumbnail>

    <HexThumbnail 
      title="Diverses" 
      thumbnail="img/map_img1 (Large).png" 
      link="/diverses"
      subtext="Einige Kunstprojekte"></HexThumbnail>
  </div>
</template>

<script>
import HexThumbnail from './HexThumbnail.vue';

export default {
    name: 'FreeformGallery',
    components: { HexThumbnail }
}

</script>

<style lang="scss">


@import "/node_modules/bulma/bulma";

.hex-gallery {
  width: var(--tw);
  height: var(--th);

  margin: auto;
  position: relative;
}

.hex {
  width: var(--w);
  height: var(--h);
}

.hex-box {
  position: absolute;
  z-index: 2;
  height: 100%;
  
  width: 100%;
  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  opacity: 0;
  transition: opacity;
}
.hex-box:hover {
  opacity: 1.0;
}

:root {
  --gh: calc(var(--g) * sqrt(3) /2);

  --h: calc(var(--w) *2 /sqrt(3));
}

@include touch {

  :root {
    --g: 2vw;
    
    --tw: calc(100vw - 2*#{$gap});
    --w: calc((var(--tw) - var(--g))/1.5);
    
    --th: calc((var(--h) *22 /4) + (var(--gh) * 6));
  }

  .hex-gallery {
    margin-left: 0px;
  }

  .container {
    max-width: calc(#{$tablet} - (2 * #{$gap}));
  }

  /* #region hexes */

.hex:nth-child(1) {
  top: 0px;
  left: calc(var(--w) /2 + var(--g));
}

.hex:nth-child(2) {
  top: calc((var(--h) *3 /4) + var(--gh));
  left: 0px;
}

.hex:nth-child(3) {
  top: calc(2*((var(--h) *3 /4) + var(--gh)));
  left: calc(var(--w) /2 + var(--g));
}

.hex:nth-child(4) {
  top: calc(3*((var(--h) *3 /4) + var(--gh)));
  left: 0px;
}

.hex:nth-child(5) {
  top: calc(4*((var(--h) *3 /4) + var(--gh)));
  left: calc(var(--w) /2 + var(--g));
}

.hex:nth-child(6) {
  top: calc(5*((var(--h) *3 /4) + var(--gh)));
  left: 0px;
}

.hex:nth-child(7) {
  top: calc(6*((var(--h) *3 /4) + var(--gh)));
  left: calc(var(--w) /2 + var(--g));
}

/* #endregion */
}

@include desktop-only {

  :root {
    --g: 2vw;
    
    --tw: calc(100vw - 3*#{$gap} - 200px);
    --w: calc((var(--tw) - var(--g))/2);
    
    --th: calc((var(--h) *16 /4) + (var(--gh) * 4));
  }

  .container {
    max-width: $desktop;
  }

  /* #region hexes */

  .hex:nth-child(1) {
    top: 0px;
    left: calc((var(--w) + var(--g))/2);
  }
  
  .hex:nth-child(2) {
    top: calc((var(--h) /4 *3) + var(--gh));
    left: 0px;
  }
  
  .hex:nth-child(3) {
    top: calc((var(--h) /4 *3) + var(--gh));
    left: calc(var(--w) + var(--g));
  }
  
  .hex:nth-child(4) {
    top: calc(2 * ((var(--h) /4 *3) + var(--gh)));
    left: calc((var(--w) + var(--g))/2);
  }
  
  .hex:nth-child(5) {
    top: calc((var(--h) /4 *9) + (3 * var(--gh)));
    left: 0px;
  }
  
  .hex:nth-child(6) {
    top: calc((var(--h) /4 *9) + (3 * var(--gh)));
    left: calc(var(--w) + var(--g));
  }
  
  .hex:nth-child(7) {
    top: calc(2 * ((var(--h) /4 *6) + (2 * var(--gh))));
    left: calc((var(--w) + var(--g))/2);
  }

  /* #endregion */
}

@include widescreen {
  :root {
    // --g: 2vw;
    
    // --tw: calc(100vw - 3*#{$gap} - 250px);
    // --w: calc((var(--tw) - (2 * var(--g)))/3);
    
    // --th: calc((var(--h) * 2.5) + (var(--gh) * 2));

    --g: 2vw;
    
    --tw: calc(100vw - 2*#{$gap} - 200px);
    --w: calc((var(--tw) - (2 * var(--g)))/2.5);
    
    --th: calc((var(--h) * 3.5) + (var(--gh) * 3));
  }

  .container {
    max-width: $widescreen;
  }

  /* #region hexes */

  // .hex:nth-child(1) {
  //   top: 0px;
  //   left: calc((var(--w) + var(--g))/2);
  // }
  
  // .hex:nth-child(2) {
  //   top: 0px;
  //   left: calc(1.5 * var(--w) + (1.5*var(--g)));
  // }
  
  // .hex:nth-child(3) {
  //   top: calc((var(--h) /4 *3) + var(--gh));
  //   left: 0px;
  // }
  
  // .hex:nth-child(4) {
  //   top: calc((var(--h) /4 *3) + var(--gh));
  //   left: calc(var(--w) + var(--g));
  // }
  
  // .hex:nth-child(5) {
  //   top: calc((var(--h) /4 *3) + var(--gh));
  //   left: calc(2*(var(--w) + var(--g)));
  // }
  
  // .hex:nth-child(6) {
  //   top: calc(2 * ((var(--h) /4 *3) + var(--gh)));
  //   left: calc((var(--w) + var(--g))/2);
  // }
  
  // .hex:nth-child(7) {
  //   top: calc(2 * ((var(--h) /4 *3) + var(--gh)));
  //   left: calc(1.5 * var(--w) + (1.5*var(--g)));
  // }

  .hex:nth-child(1) {
    top: 0px;
    left: calc((var(--w) + var(--g))/2);
  }
  
  .hex:nth-child(2) {
    top: 0px;
    left: calc(1.5 * var(--w) + (1.5*var(--g)));
  }
  
  .hex:nth-child(3) {
    top: calc((var(--h) /4 *3) + var(--gh));
    left: 0px;
  }
  
  .hex:nth-child(4) {
    top: calc((var(--h) /4 *3) + var(--gh));
    left: calc(var(--w) + var(--g));
  }
  
  .hex:nth-child(5) {
    top: calc(2 * ((var(--h) /4 *3) + var(--gh)));
    left: calc((var(--w) + var(--g))/2);
  }
  
  .hex:nth-child(6) {
    top: calc(2 * ((var(--h) /4 *3) + var(--gh)));
    left: calc(1.5 * var(--w) + (1.5*var(--g)));
  }
  
  .hex:nth-child(7) {
    top: calc(3 * ((var(--h) /4 *3) + var(--gh)));
    left: 0px;
  }
  
  .hex:nth-child(8) {
    top: calc(3 * ((var(--h) /4 *3) + var(--gh)));
    left: 0px;
  }

  /* #endregion */
}


</style>