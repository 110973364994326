<template>
  <div class="projekt2 has-text-centered">
    <ImageGallery class=".gallery-one desktop-only" :images="galleryImages" :columns="2"
      :width="'calc(90vw - 100px)'" :mirror-horizontal="true" />
    <ImageGallery class=".gallery-one touch-only" :images="galleryImages" :columns="1" :width="'calc(85vw)'"
      :mirror-horizontal="false" />
  </div>
</template>

<script>
import ImageGallery from '@/components/ImageGalleries/ImageGallery.vue';

export default {
  name: 'Projekt2',
  components: {
    ImageGallery
  },
  data() {
    return {
      galleryImages: [
        {
          text: [
            { text: "Scarlet Flight", classes: ['title'] },
            { text: "Soloprojekt privat, November 2023, in einer Woche", classes: ['subtitle'] },
          ]
        },
        { url: require('@/assets/thumbnails/img/onebtnplane-cover1 (Large).png') },
        {
          text: [
            { text: 'Konzept', classes: ['title'] },
            { text: 'Scarlet Flight entstand in einem Game Jam zum Thema "Flugzeug". Die Regel des Jams ist, dass das Spiel mit nur einer Taste spielbar sein muss.', classes: [] },
          ]
        },
        {
          text: [{ text: 'Ich habe mir dabei ein Propellerflugzeug vorgestellt, welches durch einen Parkour fliegen muss. Dabei können Energiekristalle aufgesammelt werden um eine Fähigkeit aufzuladen, mit welcher ein Geschwindigkeitsschub ausgelöst wird und Hindernisse zerstört werden können.', classes: [] }]
        },
        {
          text: [{ text: 'Da nur eine Taste verwendet werden darf, wird mein Flugzeug nun durch Tippen der Leertaste gesteuert, wie Flappy Bird. Die Fähigkeit soll durch Gedrückthalten der Leertaste ausgelöst werden können. ', classes: [] }]
        },
        {
          text: [
            { text: 'Ästethik', classes: ['title'] },
            { text: 'Die Welt, durch die das scharlachrote Flugzeug fliegt, ist dunkel. Am Himmel leuchten Sternen, die Umrisse hoher Gebäude sind zu erkennen.', classes: [] },
          ]
        },
        {
          text: [{ text: 'Für den Hintergrund und die Parkourelemente und Hindernisse werden dunkle Grau- und Weisstöne verwendet. Das Flugzeug, Energiekristalle, und Energiegeladene Objekte sind durch ihre rote Farbe ausgezeichnet und stechen sofort ins Auge.', classes: [] }]
        },
        { url: require('@/assets/thumbnails/thumbnail scarlet-fligth (Large).png')},
        {
          text: [
            { text: 'Prozess', classes: ['title'] },
            { text: 'Ich habe das Spiel mit der Godot Game Engine entwickelt, welche ich während des Jams erlernte.', classes: [] },
          ]
        },
        {
          text: [{ text: 'Da es ein kurzer Game Jam ist und ich die Game Engine noch nicht gut kannte, habe ich nur einen Prototypen entwickelt. Darin implementiert sind bereits das Steuern des Flugzeugs, die Hindernisse, welche zufällig plaziert werden, der Hintergrund mit Parallax-Effekt, und ein stetig steigender Schwierigkeitsgrad.', classes: [] }]
        },
        {
          text: [{ text: 'Während des Jams habe ich die Basics von Godot und ihre eigene Programmiersprache GDScript, sowie Konzepte wie Datenpersistenz und Spielerinput in Godot erlernt.', classes: [] }]
        },
        {
          text: [
            { text: 'Zukuntspläne', classes: ['title'] },
            { text: 'Da das Spiel nur mit einer Taste gespielt werden kann, würde es perfekt auf ein Smartphone passen.', classes: [] },
          ]
        },
        {
          text: [{ text: 'Momentan ist der Prototyp nur in Webbrowser auf www.telkinf.itch.io/scarlet-flight spielbar, aber als nächsten Schritt würde ich definitiv Touchscreensupport einbauen und den Prototyp als App auf dem Handy spielbar machen.', classes: [] }]
        },
        // {
        //   text: [{ text: '', classes: [] }]
        // },
        // {
        //   text: [
        //     { text: 'title', classes: ['title'] },
        //     { text: '', classes: [] },
        //   ]
        // },
        // { url: require('@/assets/thumbnails/thumbnail dodge-game (Large).png'), alt: 'Custom Image 1' },
        // { url: require('@/assets/thumbnails/thumbnail dodge-game (Large).png'), alt: 'Custom Image 1' },
      ],
      // galleryImagesDesktop: [
      //   {
      //     text: [
      //       { text: "Dodge Game", classes: ['title'] },
      //       { text: "2022, Solo Project", classes: ['subtitle'] },
      //     ]
      //   },
      //   // { url: require('@/assets/thumbnails/thumbnail dodge-game (Large).png'), alt: 'Custom Image 1' },
      //   {
      //     text: [{ text: '', classes: [] }]
      //   },
      //   // { url: require('@/assets/thumbnails/thumbnail dodge-game (Large).png'), alt: 'Custom Image 1' },
      // ],
    };
  }
}
</script>

<style scoped lang="scss">
@import "/node_modules/bulma/bulma";
</style>