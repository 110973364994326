<template>
  <div class="hausaufgabe has-text-centered">
    <ImageGallery class=".gallery-one desktop-only" :images="galleryImages" :columns="2" :width="'calc(90vw - 100px)'"
      :mirror-horizontal="true" />
    <ImageGallery class=".gallery-one touch-only" :images="galleryImages" :columns="1" :width="'calc(85vw)'"
      :mirror-horizontal="false" />
  </div>
</template>

<script>
import ImageGallery from '@/components/ImageGalleries/ImageGallery.vue';

export default {
  name: 'Hausaufgabe',
  components: {
    ImageGallery
  },
  data() {
    return {
      galleryImages: [
        {
          text: [
            { text: "Hausaufgabe", classes: ['title'] },
            { text: "Im Moment", classes: ['title'] },
          ]
        },
        {
          text: [
            { text: "Hausaufgabe zum Thema 'Im Moment', Februar 2024, 2 Wochen", classes: ['subtitle'] },
          ]
        },
        {
          text: [{ text: 'Mein Ziel ist es, ein Gamekonzept zu entwickeln.', classes: [] }]
        },
        {
          text: [{ text: 'Mein erster Gedanke war, die Spielenden verschiedene wichtige Momente im Leben eines Charakters erleben zu lassen, wie z.B. glückliche Kindheitserinnerungen, die Heirat, oder ein Familienausflug. Durch diese Momente würde eine Geschichte erzählt werden.', classes: [] }]
        },
        {
          text: [{ text: 'Eine andere Idee war, dass die Spielenden die Zeit manipulieren können, somit den \'Moment\' verändern können. Alle Ideen gefielen mir nicht besonders, oder sind schwierig umsetzbar, doch dann hatte ich einen Geistesblitz.', classes: [] }]
        },
        {
          text: [
            { text: 'Konzept', classes: ['title'] },
            { text: '', classes: [] },
          ]
        },
        {
          text: [{ text: 'Ein aufblühendes Dorf prosperiert dank der Resourcen der Natur, bedroht diese aber mit Übernutzung und Zerstörung, da die Bewohner nur im Moment leben und nicht in die Zukunft denken. ', classes: [] }]
        },
        {
          text: [{ text: 'Die Spielenden versuchen, die Bevölkerung davon zu überzeugen, die Natur zu schützen, um ein ökonomisches und ökologisches Disaster abzuwenden.' }]
        },
        {
          text: [
            { text: 'Konzept detailliert', classes: ['title'] },
            { text: '', classes: [] },
          ]
        },
        {
          text: [{ text: 'Im Spiel ist der Hauptcharakter, wie viele andere auch, zu einem neu entdeckten Kontinent gereist. Dort blüht gerade das erste Dorf auf. Der Charakter lernt einige Personen kennen und bekommt ein Gefühl für diese neue Umgebung.', classes: [] }]
        },
        {
          text: [{ text: 'Die Leute sind glücklich, das Dorf wimmelt mit Aktivität. In der nahen Umgebung liegen grosse Wälder, Wildtiere ziehen in grossen Herden umher, und an der Küste wird viel Fisch gefangen.', classes: [] }]
        },
        {
          text: [{ text: 'Dank dieser Masse an Resourcen kann sich jeder sein Leben so gestalten, wie sie es möchten.', classes: [] }]
        },
        {
          text: [{ text: 'Das hat aber auch eine Dunkle Seite: um Maschinen, Häuser und andere Produkte zu produzieren werden viele Bäume gefällt und die Waldtiere gejagt. Das Dorf wird zu einer Stadt, die Industrie wird grösser, der Lebenskomfort steigt.', classes: [] }]
        },
        {
          text: [{ text: 'Die Bewohner leben \'im Moment\', sehen nur kurzfristig in die Zukunft und beuten die Fauna und Flora in einem rasanten Tempo aus.', classes: [] }]
        },
        {
          text: [{ text: 'Die Spielenden merken, dass irgendwann die Resourcen verschwinden werden, alle Wälder abgeholzt, die Tiere durch die Jagd stark dezimiert und vertrieben. An diesem Punkt wird die Industrie zusammenbrechen, die Leute werden nicht mehr leben können.', classes: [] }]
        },
        {
          text: [{ text: 'Die Aufgabe der Spielenden ist es, diesen Kurs zu ändern, sodass dieses Szenario nicht eintreffen wird. Führerpersonen in der Industrie und die normale Bevölkerung müssen davon überzeugt werden, die Natur zu schützen.', classes: [] }]
        },
        {
          text: [
            { text: 'Game Mechaniken', classes: ['title'] },
            { text: '', classes: [] },
          ]
        },
        {
          text: [{ text: 'Nun, da die Story des Spieles festgelegt ist, habe ich mir einige Mechaniken überlegt, welche das Spiel formen.', classes: [] }]
        },
        {
          text: [
            { text: 'Open World', classes: ['subtitle']},
            { text: ' Die Spielenden können die Welt selber erkunden und verschiedene Points-of-Interest besuchen.', classes: [] }
          ]
        },
        {
          text: [
            { text: 'Umgebungsveränderungen', classes: ['subtitle'] },
            { text: 'Der Wald ist am Anfang gross und schön. ', classes: [] }
          ]
        },
        {
          text: [{ text: 'Im Verlaufe des Spieles werden immer grössere Teile abgeholzt, welche unschöne leere Flächen hinterlassen.', classes: [] }]
        },
        {
          text: [{ text: 'Auch die Wildtiere werden mit der Zeit immer seltener. Wo die Spielenden anfangs oft eine Herde Rehe oder Wildschweine umherstreichen sehen, verschwinden diese gegen Ende des Spieles vollständig.', classes: [] }]
        },
        {
          text: [{ text: 'Das Dorf, welches zuerst klein ist, wird stetig grösser, da mit dem Holz des Waldes immer mehr Häuser gebaut werden.', classes: [] }]
        },
        {
          text: [
            { text: 'Personen beeinflussen', classes: ['subtitle'] },
            { text: '', classes: [] }
          ]
        },
        {
          text: [{ text: 'Durch das Gespräch mit verschiedenen Charakteren können die Spielenden die Meinungen der Bevölkerung beeinflussen. Wo anfangs Abfall auf den Boden geworfen wird, regiert am Ende des Spieles der Naturschutz.', classes: [] }]
        },
        {
          text: [
            { text: 'Minigames', classes: ['subtitle'] },
            { text: 'Um verschiedene Aktionen ausführen zu können, muss ein Minigame absolviert werden.', classes: [] },
          ]
        },
        {
          text: [
            { text: 'Minigames', classes: ['title'] },
            { text: '', classes: [] },
          ]
        },
        {
          text: [{ text: 'Die Minigames sind dazu da, ein wenig Abwechslung einzuführen.', classes: [] }]
        },
        {
          text: [{ text: 'Wollen die Spielenden beispielsweise eine Person vom Naturschutz überzeugen, müssen sie in einem Minigame umherfliegenden Kontra-Argumenten ausweichen, und die Ohren des Gegenübers mit den Pro-Naturschutz-Argumenten beschiessen.', classes: [] }]
        },
        {
          text: [
            { text: 'Wichtige Momente', classes: ['title'] },
            { text: 'Hier habe ich einige wichtige Momente, welche die Spielenden erleben werden.', classes: [] },
          ]
        },
        {
          text: [{ text: 'Bei der ersten Ankunft im Dorf sieht man in der Nähe den grünen Wald und eine Herde Rehe, welche schnell darin verschwinden.', classes: [] }]
        },
        {
          text: [{ text: 'Die erste Konfrontation mit der Zerstörung der Natur sieht so aus, dass die Spielenden an einen schönen Ort im Wald gehen möchten, doch dann erkennen, dass dieser Teil des Waldes abgeholzt ist.', classes: [] }]
        },
        {
          text: [{ text: 'Der Spielcharakter brauch einen Job. Die Spielenden können zwischen der Abholz- und der Jagdgesellschaft wählen. Dort werden müssen sie verschiedene Aufgaben erledigen, bei denen sie merken, dass die Natur Schaden nimmt.', classes: [] }]
        },
        { url: require('@/assets/homework/homework_quests (Large).jpg') },
        {
          text: [
            { text: 'Das Ende', classes: ['title'] },
            { text: '', classes: [] },
          ]
        },
        {
          text: [{ text: 'Am Ende des Spieles siegt der Schutz der Natur. Die Industrie wird gedrosselt, der Wald nachgepflanzt, und den Tieren Lebensraum zurückgegeben.', classes: [] }]
        },
        {
          text: [
            { text: 'Parallele zu unserer Erde', classes: ['title'] },
            { text: '', classes: [] },
          ]
        },
        {
          text: [{ text: 'Dieses Spiel soll zeigen, was die Auswirkungen sind, wenn die Natur schutzlos ausgebeutet wird, sowohl das Aussterben von Tierarten, aber auch, was passieren kann, wenn plötzlich alle Resourcen aufgebraucht wurden.', classes: [] }]
        },
        // {
        //   text: [
        //     { text: '', classes: ['title'] },
        //     { text: '', classes: [] },
        //   ]
        // },
        // {
        //   text: [{ text: '', classes: [] }]
        // },
        // { url: require('@/assets/thumbnails/thumbnail dodge-game (Large).png'), alt: 'Custom Image 1' },
        // { url: require('@/assets/thumbnails/thumbnail dodge-game (Large).png'), alt: 'Custom Image 1' },
      ],
    };
  }
}
</script>
