<template>
  <div class="image-gallery" :style="{ width: containerWidth, height: containerHeight }">
    <div v-for="(image, index) in images" :key="index" @click="openModal(index)"
      class="gallery-image" :class="{ 'animate': !!image.url }"
        :style="{ 
          width: hexagonWidth, 
          height: hexagonHeight, 
          top: hexagonTopOffset(index), 
          left: hexagonLeftOffset(index),
          'background-color': image.background || backgroundcolor || ''

          }">

        <img v-if="image.url" :src="image.url" :alt="image.alt" />
        <div v-if="image.text" class="tiletextcontainer">
          <p v-for="(text, index) in image.text" class="tiletext" :class="text.classes"
          :style="{ color: image.foreground || '' }" 
          v-text="text.text"></p>
        </div>
    </div>

    <!-- Modal for displaying the clicked image -->
    <div v-if="modalOpen" class="modal" @click="closeModal">
      <div class="modal-content">
        <img :src="selectedImage.url" :alt="selectedImage.alt" class="modal-image" @click.stop />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
    width: {
      type: String,
      default: '150px',
    },
    columns: {
      type: Number,
      default: 1,
      required: true
    },
    mirrorHorizontal: {
      type: Boolean,
      default: false
    },
    backgroundcolor: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      modalOpen: false,
      selectedImage: {},
    };
  },
  computed: {
    containerWidth() {
      return this.width;
    },
    containerHeight() {
      switch (this.columns) {
        case 1:
          return `calc(${Math.ceil(this.images.length/1) + 1/4} * ${this.hexagonHeight} /4 *3 + (${Math.ceil(this.images.length/1)} * ${this.vertOffset}))`;
        case 2:
          return `calc(${Math.ceil(this.images.length/2) + 1/4} * ${this.hexagonHeight} /4 *3 + (${Math.ceil(this.images.length/2)} * ${this.vertOffset}))`;
        case 3:
          return `calc(${Math.ceil(this.images.length/3) + 1/4} * ${this.hexagonHeight} /4 *3 + (${Math.ceil(this.images.length/3)} * ${this.vertOffset}))`;
        default:
          break;
      }
    },
    hexagonWidth() {
      switch (this.columns) {
        case 1:
          return `calc((${this.containerWidth} - (${this.gapAmount} * 0.5)) / 1.5)`;
        case 2:
          return `calc((${this.containerWidth} - (${this.gapAmount} * 1.5)) / 2.5)`;
        case 3:
          return `calc((${this.containerWidth} - (${this.gapAmount} * 2.5)) / 3.5)`;
        default:
          break;
      }
    },
    hexagonHeight() {
      return `calc(${this.hexagonWidth} / ${Math.sqrt(3)} * 2)`;
    },
    gapAmount() {
      return '1vw';
    },
    vertOffset() {
      return `calc(${this.gapAmount} /2 *sqrt(3))`;
    }
  },
  methods: {
    openModal(index) {
      if (!this.images[index].url) return;
      this.selectedImage = this.images[index];
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    hexagonLeftOffset(index) {
      switch (this.columns) {
        case 1:
          return index % 2 === (this.mirrorHorizontal ? 0 : 1) ? '0' : `calc(${this.hexagonWidth} / 2 + (${this.gapAmount} / 2))`;
        case 2:
          return `calc(${index%2} * ${this.hexagonWidth} + ${index%2} * ${this.gapAmount} + ${Math.floor((index%4)/2)%2} * (${this.hexagonWidth} + ${this.gapAmount}) / 2)`;
        case 3:
          return `calc(${index%3} * ${this.hexagonWidth} + ${index%3} * ${this.gapAmount} + ${Math.floor((index%6)/3)%3} * (${this.hexagonWidth} + ${this.gapAmount}) / 2)`;
        default:
          break;
      }
    },
    hexagonTopOffset(index) {
      switch (this.columns) {
        case 1:
          return `calc(${Math.floor(index/1)} * ${this.hexagonHeight} / 4 * 3 + (${Math.floor(index/1)} * ${this.vertOffset}))`;
        case 2:
          return `calc(${Math.floor(index/2)} * ${this.hexagonHeight} / 4 * 3 + (${Math.floor(index/2)} * ${this.vertOffset}))`;
        case 3:
          return `calc(${Math.floor(index/3)} * ${this.hexagonHeight} / 4 * 3 + (${Math.floor(index/3)} * ${this.vertOffset}))`;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "/node_modules/bulma/bulma";

.image-gallery {
  position: relative;
}

.gallery-image {
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  transition: transform 0.2s;
  overflow: hidden;
  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.gallery-image:has(img) {
  cursor: pointer;
}

.gallery-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.gallery-image.animate:hover {
  transform: scale(1.05);
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  position: relative;
  width: 100%;
}

@include desktop {
  .modal-image {
    max-width: 80%;
    object-fit: contain; /* Maintain aspect ratio without stretching */
  }
}

@include touch {
  .modal-image {
    max-width: 100%;
    object-fit: contain; /* Maintain aspect ratio without stretching */
  }
}
</style>
