<template>
  <div class="projekt3 has-text-centered">
    <ImageGallery class=".gallery-one desktop-only" :images="galleryImages" :columns="2" :width="'calc(90vw - 100px)'"
      :mirror-horizontal="true" />
    <ImageGallery class=".gallery-one touch-only" :images="galleryImages" :columns="1" :width="'calc(85vw)'"
      :mirror-horizontal="false" />
  </div>
</template>

<script>
import ImageGallery from '@/components/ImageGalleries/ImageGallery.vue';

export default {
  name: 'Projekt3',
  components: {
    ImageGallery,
  },
  data() {
    return {
      galleryImages: [
        {
          text: [
            { text: "FPS Runner", classes: ['title'] },
            { text: "Soloprojekt privat, Mai 2023, 2 Wochen", classes: ['subtitle'] },
          ]
        },
        { url: require('@/assets/thumbnails/thumbnail fps-runner (Large).png')},
        {
          text: [
            { text: 'Idee', classes: ['title'] },
            { text: 'Die Idee für dieses Game hatte ich, nachdem ich ein Video über das Gravitationssystem in Super Mario Galaxy gesehen habe.', classes: [] },
          ]
        },
        {
          text: [{ text: 'Darin wird erläutert, wie Mario auf runden, zylindrischen, donatförmigen, usw. Planeten angezogen wird. Ausserdem wird gezeigt, wie diese Schwerkraft implementiert wurde, und welche Nachteile dabei berücksichtigt werden mussten.', classes: [] }]
        },
        {
          text: [{ text: 'Ich fand das Thema unglaublich spannend und wollte selber ein solches Gravitationssystem in Unity programmieren, und dieses auch gleich in ein Game einbauen.', classes: [] }]
        },
        {
          text: [
            { text: 'Konzept', classes: ['title'] },
            { text: 'Die Spielidee, welche ich entwickeln wollte, ist ein Endless-Runner (wie z.B. Subway Surfer).', classes: [] },
          ]
        },
        {
          text: [{ text: 'Der Spieler rennt dabei in der Ich-Perspektive über eine Strecke, welche prozedural generiert wird. In manchen Teilen der Strecke ändert sich dabei die Schwerkraft, sodass der Spielcharakter plötzlich an der Wand läuft, in einem Zylinder eine Spirale abläuft, oder gar keine Schwerkraft mehr da ist.', classes: [] }]
        },
        {
          text: [
            { text: 'Gravitationssystem', classes: ['title'] },
            { text: 'Mein erstes Ziel war es, ein paar einfache Gravitationsfelder in Unity zu implementieren.', classes: [] },
          ]
        },
        {
          text: [{ text: 'Die Unity Physik Engine schaltete ich aus, damit diese nicht mit meinem Gravitationscode zusammenstösst.Als erstes baute ich kugelförmige Schwerkraft. Wenn der Spielcharakter in eine der unsichtbaren Gravitations-Kugel-Zone hineinläuft, soll ihn die Schwerkraft in die Mitte der Kugel ziehen.', classes: [] }]
        },
        {
          text: [{ text: 'Danach kommt die unsichtbare Box-Zone. Eine Seite der Box ist definiert als \'Unten\', und wenn der Spielcharakter die Box berührt, wird die Schwerkraft ihn in die Richtung dieser Seite ziehen.', classes: [] }]
        },
        {
          text: [{ text: 'Nun kommt das Gravitationsscript für den Spieler. Ich habe es so geschrieben, dass es nicht nur auf dem Spielcharakter funktioniert, sondern es auch an andere Unity Objekte hinzugefügt werden kann, damit diese auch meinen Schwerkraftsregeln folgen.', classes: [] }]
        },
        {
          text: [{ text: 'Das Script merkt sich, in welcher Zone sich das Objekt gerade befindet. Ist es in keiner Zone, wird die normale Schwerkraft nach unten angenommen. Ist es in mehreren Zonen, bestimmt ein Prioritätssystem, welche Zone genutzt werden sollte. Das Objekt wird dann in die Richtung beschleunigt, die gemäss der aktiven Zone unten ist.', classes: [] }]
        },
        {
          text: [{ text: 'Für den Spielcharakter musste ich noch eine Funktion implementieren, welche den Spieler immer so rum dreht, dass seine Füsse gemäss der aktiven Zone nach unten zeigen und seine Blickrichtung vorsichtig korrigiert, sodass die Spielenden den Überblick über oben und unten nicht verlieren.', classes: [] }]
        },
        {
          text: [{ text: 'Nach der Kugel und der Box entwickelte ich die Zylinderzone, mit welcher der Spieler auf der Mantelfläche eines Zylinders herumlaufen kann.', classes: [] }]
        },
        {
          text: [
            { text: 'Prozedurale Strecke', classes: ['title'] },
            { text: 'Um die Strecke prozedural generieren zu können, habe ich ein System programmiert, welches verschiedene Streckenblöcke aneinanderreiht.', classes: [] },
          ]
        },
        { url: require('@/assets/thumbnails/img/fpsrunner_img2 (Large).png') },
        { url: require('@/assets/thumbnails/img/fpsrunner_img3 (Large).png') },
        {
          text: [{ text: 'Dann habe ich einige Streckenblöcke in Unity erstellt, unter anderem ein gerades Streckenstück und eine Links- und Rechtskurve. ', classes: [] }]
        },
        {
          text: [{ text: 'Wenn nun der Spielcharakter auf den Startblock geht, werden ein paar neue Blöcke zufällig aneinandergereiht. Geht der Spielcharakter dann auf diese Blöcke, werden wiederum neue Blöcke weiter vorne erstellt, sodass die Spielenden ohne Ende der Strecke folgen können.', classes: [] }]
        },
        {
          text: [{ text: 'Um Computerresourcen zu sparen werden die Blöcke ausserdem wieder entfernt, wenn der Spielcharakter sich zu weit von ihnen entfernt.', classes: [] }]
        },
        {
          text: [{ text: 'Die Streckenbaublöcke haben noch eine Funktion: manche Blöcke, wie z.B. das gerade Streckenstück generieren wiederum zufällige Hindernisse auf sich drauf. ', classes: [] }]
        },
        {
          text: [{ text: 'Somit wird die Strecke vielfaltiger aussehen, und durch diese zufällig platzierten Hindernissen oder Strukturen wiederholen sich die gleichen Blöcke weniger.', classes: [] }]
        },
        { url: require('@/assets/thumbnails/img/fpsrunner_img4 (Large).png') },
        {
          text: [
            { text: 'Game Prototyp', classes: ['title'] },
            { text: '', classes: [] },
          ]
        },
        {
          text: [{ text: 'Nun da Gravitationssystem und die Prozeduralen Strecke funktionierten, konnte ich sie zusammenfügen.', classes: [] }]
        },
        {
          text: [{ text: 'Dem Streckensystem habe ich neue Streckenblöcke hinzugefügt, welche Gravitationszonen besitzen, wie z.B. ein Stück, in welchem sich der Boden stetig dreht, bis die Strecke um 90 Grad gedreht wurde und der Spielcharakter an der Wand weiterläuft.', classes: [] }]
        },
        {
          text: [{ text: 'Da das Game ein Endless-Runner sein soll, will ich einen Fokus auf das \'Running\', auf Geschwindigkeit haben. ', classes: [] }]
        },
        {
          text: [{ text: 'Der Spielcharakter wird langsam, aber stetig immer schneller, solande er in Bewegung bleibt. Somit können die Spielenden einen Schwung aufbauen und immer schneller werden. Somit steigt auch die Schwierigkeit stetig an.', classes: [] }]
        },
        // {
        //   text: [
        //     { text: 'title', classes: ['title'] },
        //     { text: '', classes: [] },
        //   ]
        // },
        // {
        //   text: [
        //     { text: 'title', classes: ['title'] },
        //     { text: '', classes: [] },
        //   ]
        // },
        // { url: require('@/assets/thumbnails/thumbnail dodge-game (Large).png'), alt: 'Custom Image 1' },
        // { url: require('@/assets/thumbnails/thumbnail dodge-game (Large).png'), alt: 'Custom Image 1' },
      ],
    };
  }
}
</script>

<style scoped lang="scss">
.projekt3 {
  position: relative;
}

.mytile {
  position: relative;
  // margin-left: 100px;
}
</style>
