<template>
  <div class="diverses has-text-centered">
    <ImageGallery class=".gallery-one desktop-only" :images="galleryImages" :columns="2" :width="'calc(90vw - 100px)'"
      :mirror-horizontal="true" />
    <ImageGallery class=".gallery-one touch-only" :images="galleryImages" :columns="1" :width="'calc(85vw)'"
      :mirror-horizontal="false" />
  </div>
</template>

<script>
import ImageGallery from '@/components/ImageGalleries/ImageGallery.vue';

export default {
  name: 'Diverses',
  components: { ImageGallery },
  data() {
    return {
      galleryImages: [
        // {
        //   text: [
        //     { text: "", classes: ['title'] },
        //     { text: "", classes: ['subtitle'] },
        //   ]
        // },
        // {
        //   text: [{ text: '' }]
        // },
        {
          text: [
            { text: "Diverses", classes: ['title'] },
            { text: "Eine Gallerie kleinerer Arbeiten", classes: ['subtitle'] },
          ]
        },
        // {
        //   text: [{ text: '' }]
        // },
        { text: [
          { text: 'Bilder, Zeichnungen:', classes: ['subtitle']}
        ]},
        { url: require('@/assets/art/firstmap (Large).jpg') },
        { url: require('@/assets/art/secondmap (Large).jpg') },
        { url: require('@/assets/art/map scraps (Large).jpg') },
        { url: require('@/assets/art/bastion nature (Large).jpg') },
        { url: require('@/assets/art/bastion war (Large).jpg') },
        { text: [
          { text: 'Menschen zeichnen:', classes: ['subtitle']}
        ]},
        { url: require('@/assets/art/anatomy sketches (Large).jpg') },
        { url: require('@/assets/art/bodies collection (Large).jpg') },
        { url: require('@/assets/art/heads collection (Large).jpg') },
        { url: require('@/assets/art/anatomy sketches (Large).jpg') },
        { text: [
          { text: 'Origami:', classes: ['subtitle']}
        ]},
        { url: require('@/assets/art/origami hanging (Large).jpg') },
        { url: require('@/assets/art/origami shapes (Large).jpg') },
        { url: require('@/assets/art/origami panels (Large).jpg') },
        { url: require('@/assets/art/moonlamp (Large).jpg') },
        { text: [
          { text: 'Basteleien:', classes: ['subtitle']}
        ]},
        { url: require('@/assets/art/gladoslying (Large).jpg') },
        { url: require('@/assets/art/timebox (Large).jpg') },
        { text: [
          { text: 'Gameprojekte:', classes: ['subtitle']}
        ]},
        { url: require('@/assets/thumbnails/thumbnail tictactoe-desktop (Large).png') },
        { url: require('@/assets/thumbnails/thumbnail casino-game-blackjack (Large).png') },
        { url: require('@/assets/thumbnails/thumbnail dodge-game (Large).png') },
        { url: require('@/assets/thumbnails/thumbnail jetfighters-1.1 (Large).png') },
        // { url: require('@/assets/thumbnails/thumbnail jetfighters (Large).png') },
        { url: require('@/assets/thumbnails/thumbnail snake-game (Large).png') },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.diverses {
  padding: 50px;
}

.grid {
  width: 80%;
  // border: red solid 1px;
  margin: auto;
}
</style>
