<template>
  <div class="projekt5 has-text-centered">
    <h1 class="has-text-white">This is project 5. ImageGallery columns:1 width:calc(80vw-100px)</h1>
    <ImageGallery :width="'calc(80vw - 100px)'" :columns="1" :mirror-horizontal="true" :images="[
        { url: require('@/assets/logo (Large).png'), alt: 'Custom Image 1' },
        { url: require('@/assets/square (Large).png'), alt: 'Custom Image 2' },
        { url: require('@/assets/square (Large).png'), alt: 'Custom Image 2' },
        { text: 'testtext', background: 'var(--cdark)', foreground: 'var(--clight)' },
        { url: require('@/assets/square (Large).png'), alt: 'Custom Image 2' },
        { url: require('@/assets/square (Large).png'), alt: 'Custom Image 2' },
        { url: require('@/assets/square (Large).png'), alt: 'Custom Image 2' },
        { url: require('@/assets/square (Large).png'), alt: 'Custom Image 2' },
        { url: require('@/assets/square (Large).png'), alt: 'Custom Image 2' },
    ]"></ImageGallery>
  </div>
</template>

<script>
import ImageGallery from '@/components/ImageGalleries/ImageGallery.vue';

export default {
  name: 'Projekt5',
  components: {
    ImageGallery
  }
}
</script>
